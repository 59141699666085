<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">专项管理保护法律、法规</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="类别" prop="LB" :style="{ width: '80%' }">
                    <el-select
                        v-model="formdata.dataDic.LB"
                        :disabled="!isEdit"
                        placeholder="请选择状态"
                    >
                        <el-option label="地方性法规" value="0"></el-option>
                        <el-option label="地方政府规章" value="1"></el-option>
                        <el-option label="地方规范性文件" value="2"></el-option>
                        <el-option
                            label="保护管理机构内部制度"
                            value="3"
                        ></el-option>
                        <el-option label="其他" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" prop="MC" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.MC"
                        :disabled="!isEdit"
                        placeholder="请填写名称"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="公布时间"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.GBSJ"
                        type="date"
                        :disabled="!isEdit"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择公布时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="公布文号"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.GBWH"
                        :disabled="!isEdit"
                        placeholder="请填写公布文号"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item label="实施时间" :style="{ width: '80%' }">
                    <el-date-picker
                        v-model="formdata.dataDic.SSSJ"
                        type="date"
                        :disabled="!isEdit"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择实施时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="状态" prop="ZT" :style="{ width: '80%' }">
                    <el-select
                        v-model="formdata.dataDic.ZT"
                        :disabled="!isEdit"
                        placeholder="请选择状态"
                    >
                        <el-option label="生效" value="0"></el-option>
                        <el-option label="废止" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="文件内容"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="formdata.itemCode"
                        :metaData.sync="formdata.metaJson"
                        basicType="document"
                        :showFileDetail="true"
                        @handleChange="handleChange"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    mixins: [infoMixin],
    data() {
        return {
            isEdit: true,
            saveButton: true,
            formdata: {
                dataDic: {
                    LB: 0,
                    MC: null,
                    GBSJ: null,
                    GBWH: null,
                    SSSJ: null,
                    ZT: null,
                },
                fileids: "",
                itemCode: "202",
                metaJson: [],
            },
            rules: {
                LB: [
                    { required: true, message: "请填写类别", trigger: "blur" },
                ],
                mc: [
                    { required: true, message: "请填写名称", trigger: "blur" },
                ],
                zt: [
                    { required: true, message: "请选择状态", trigger: "blur" },
                ],
            },
        };
    },
    methods: {},
};
</script>

<style></style>
